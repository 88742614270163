
import { Link } from 'react-router-dom';
import './App.scss';
import logoWhite from "assets/logo-white.svg"

function App() {
  return (
    <div className="App">
      <img src={logoWhite} alt='brand logo white' />
      <div className='write-up'>
        <h1>We give best prices to either Buy, or Finance Mobile Phones, or Laptops</h1>

        <h2>We are launching soon! You can <Link to="mailto:fullstackbusiness247@gmail.com">Contact Us</Link> to know more about our offers.</h2>

      </div>
    </div>
  );
}

export default App;
